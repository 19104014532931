import React, { useEffect, useState } from 'react';
import filterTableIcon from '../../assets/filterTableIcon.png';
import '../Referral/Referral.css';
import { IoEyeOutline } from 'react-icons/io5';
import axios from 'axios';
import { URLS } from '../../Utils/app_urls';
import { useNavigate } from 'react-router-dom';
import Loader from '../../components/Loader/Loader';
import conciseAddress from '../../Utils/ConciseAddress';

export default function ReferalReward() {
  const navigate = useNavigate();
  const [activePageNo, setActivePageNo] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [totalResult, setTotalResult] = useState(0);
  const [loader, setLoader] = useState(false);
  const [referralData, setReferralData] = useState([]);
  const [refFilterData, setRefFilterData] = useState([]);

  const handlePageNumber = (pageNo) => {
    if (pageNo >= 1 && pageNo <= totalPages) {
      setActivePageNo(pageNo);
      getReferralData(pageNo);
    }
  };
  
  const getReferralData = async (page=1, size=10) => {
    setLoader(true);
    try {
      const { data } = await axios.get(URLS.getReferalReward(page, size), {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('acessToken')}`,
          Accept: 'application/json'
        }
      });
      setReferralData(data?.data?.result);
      setTotalPages(Math.ceil(data?.data?.totalResult / data?.data?.pageSize));
      setTotalResult(data?.data?.totalResult)
    } catch (error) {
      console.error(error);
    } finally {
      setLoader(false);
    }
  };

  const handleSorting = async (order) => {
    setLoader(true);
    try {
      const { data } = await axios.get(`${URLS.getAllReferral}/${order}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('acessToken')}`,
        },
      });
      setRefFilterData(data?.data?.result || []);
    } catch (error) {
      console.error(error);
    } finally {
      setLoader(false);
    }
  };

  const handleRedirectToDetailsPage = (id, walletaddress) => {
    navigate(`/affiliate/referral-reward-details`, { state: {
        id, walletaddress
    } });
  };

  useEffect(() => {
    getReferralData();
  }, []);

  useEffect(() => {
    setRefFilterData(referralData);
  }, [referralData]);

  const handleFilterTable = (e) => {
    const filterTextName = e.target.value;
    const filterText = filterTextName?.toLowerCase();
    if (filterText === '') {
      setRefFilterData(referralData);
    } else {
      const filteredData = referralData.filter(({ user_address, referred_user, total_claimed, claimAbleAmount, mforge_token }) =>
        user_address?.toString()?.toLowerCase().includes(filterText) ||
        referred_user?.toString()?.toLowerCase().includes(filterText) ||
        total_claimed?.toString()?.toLowerCase().includes(filterText) ||
        claimAbleAmount?.toString()?.toLowerCase().includes(filterText) ||
        mforge_token?.toString()?.toLowerCase().includes(filterText)
      );
      setRefFilterData(filteredData);
    }
  };

  const daysLeft =(dateStrig)=>{
    const date = new Date(dateStrig);
    const today = new Date();
    const ms = date - today;
    const msInDay = 24*60*60*1000;
    const result = Math.ceil(ms/msInDay);
    return result;
  }

  console.log(refFilterData, 'this dekojfdsf')
  
  if(loader) return <Loader />;
  return (
    <>
      <div className="referral_container">
        <div className="referralTable">
          <h1>Referral Rewards</h1>
          <div className='d-flex justify-content-end flex-wrap gap-2 align-items-center'>
            <input type="text" placeholder='Search' onChange={handleFilterTable} />
          </div>
          <div className='w-100 responsiveTableParent'>
            <table className='w-100'>
              <thead className='affTableHeader'>
                <tr>
                  <th className='boder-Rght'>User Address</th>
                  <th className='boder-Rght'>Refered User</th>
                  <th className='boder-Rght'>Amount Earn</th>
                  <th className='boder-Rght'>Amount to Claim</th>
                  <th className='boder-Rght'>MForge Token</th>
                </tr>
              </thead>
              <tbody className='referralTableBody'>
                {refFilterData.length > 0 ? (
                  refFilterData.map((referral) => (
                    <tr key={referral?._id}>
                      <td className='text-capitalize'>{conciseAddress(referral?.user_address)}</td>
                      <td className='text-capitalize'>{conciseAddress(referral?.referred_user)}</td>
                      <td className='text-capitalize'>{referral?.total_claimed}</td>
                      <td className='text-capitalize'>{referral?.claimAbleAmount}</td>
                      <td className='text-capitalize'>{referral?.mforge_token}</td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="5" className='data_not fw-bold'>No Data Available</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
          <div className='w-100 d-flex justify-content-between'>
            <span className='fw-bold'>Showing {activePageNo} to {totalPages} of {totalResult} Entries</span>
            <div className='AffTxTableButton'>
              <button disabled={activePageNo === 1} onClick={() => handlePageNumber(activePageNo - 1)}>
                <span>Prev</span>
              </button>
              {((totalPages > 0) ? [...Array(totalPages).keys()] : [...Array(1).keys()])
              .slice(Math.max(0, activePageNo - 3), Math.min(totalPages, activePageNo + 2))
              .map((_, index) => (
                  <button
                      className={`${activePageNo === index + Math.max(0, activePageNo - 3) + 1 && 'activePaginationbtn'}`}
                      key={index + Math.max(0, activePageNo - 3) + 1}
                      onClick={() => handlePageNumber(index + Math.max(0, activePageNo - 3) + 1)}
                  >
                      {index + Math.max(0, activePageNo - 3) + 1}
                  </button>
              ))}
              <button disabled={activePageNo === totalPages} onClick={() => handlePageNumber(activePageNo + 1)}>
                <span>Next</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}



