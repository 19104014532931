import React, { useEffect, useState } from 'react';
import '../Referral/Referral.css';
import { IoEyeOutline } from 'react-icons/io5';
import axios from 'axios';
import { URLS } from '../../Utils/app_urls';
import { useNavigate } from 'react-router-dom';
import Loader from '../../components/Loader/Loader';

export default function LeadershipBoard() {
  const navigate = useNavigate();
  const [activePageNo, setActivePageNo] = useState(1);
  const [totalPageNo, setTotalPageNo] = useState(1);
  const [loader, setLoader] = useState(false);
  const [leadershipBoardData, setLeadershipBoardData] = useState([]);
  const [refFilterData, setRefFilterData] = useState([]);

  useEffect(() => {
    fetchLeadershipBoardData(1);
  }, []);

  useEffect(() => {
    setRefFilterData(leadershipBoardData);
  }, [leadershipBoardData]);

  const fetchLeadershipBoardData = async (pageNo) => {
    setLoader(true);
    try {
      const { data } = await axios.get(`${URLS.getAllLeadership}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('acessToken')}`,
        },
      });
      setLeadershipBoardData(data?.data || []);
      setTotalPageNo(Math.max(1, Math.ceil((data?.data?.total || 0) / 10)));
    } catch (error) {
      // console.error(error);
    } finally {
      setLoader(false);
    }
  };

  const handlePageNumber = (pageNo) => {
    if (pageNo >= 1 && pageNo <= totalPageNo) {
      setActivePageNo(pageNo);
      fetchLeadershipBoardData(pageNo);
    }
  };

  const handleRedirectToDetailsPage = (level) => {
    navigate(`/affiliate/leaderboard-matrix-details`, { state: level });
  };

  const handleFilterTable = (e) => {
    const filterText = e.target.value.toLowerCase();
    if (!filterText) {
      setRefFilterData(leadershipBoardData);
    } else {
      const filteredData = leadershipBoardData.filter(({ qualifyAmountInUSD, poolSharePercentage, level, level1ContributionPercentage }) =>
        qualifyAmountInUSD.toString().toLowerCase().includes(filterText) ||
        poolSharePercentage.toString().toLowerCase().includes(filterText) ||
        level.toString().toLowerCase().includes(filterText) ||
        level1ContributionPercentage.toString().toLowerCase().includes(filterText)
      );
      setRefFilterData(filteredData);
    }
  };

  if(loader) return <Loader />
  return (
    <>
      <div className="referral_container">
        <div className="referralTable">
          <h1>LeadershipBoard Matrix</h1>
          <div className='d-flex justify-content-end flex-wrap gap-2 align-items-center'>
            <input type="text" placeholder='Search' onChange={handleFilterTable} />
          </div>
          <div className='w-100 responsiveTableParent'>
            <table className='w-100'>
              <thead className='affTableHeader'>
                <tr>
                  <th className='boder-Rght'>Qualify Amount (USD)</th>
                  <th className='boder-Rght'>Pool Share %</th>
                  <th className='boder-Rght'>Level</th>
                  <th className='boder-Rght'>Level 1 Contribution</th>
                  <th className='boder-Rght'>View</th>
                </tr>
              </thead>
              <tbody className='referralTableBody'>
                {refFilterData.length > 0 ? (
                  (refFilterData || []) .map((referral) => (
                    <tr key={referral?._id}>
                      <td>{referral?.qualifyAmountInUSD.$numberDecimal || referral?.qualifyAmountInUSD}</td>
                      <td>{referral?.poolSharePercentage.$numberDecimal || referral?.poolSharePercentage}</td>
                      <td>{referral?.level}</td>
                      <td>{referral?.level1ContributionPercentage.$numberDecimal || referral?.level1ContributionPercentage}</td>
                      <td>
                        <span onClick={() => handleRedirectToDetailsPage(referral?.level)}>
                          <IoEyeOutline fontSize={20} />
                        </span>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="5" className='data_not fw-bold'>No Data Available</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
          {/* <div className='w-100 d-flex justify-content-between'>
            <span className='fw-bold'>Showing {activePageNo} to {totalPageNo} of {totalPageNo} Entries</span>
            <div className='AffTxTableButton'>
              <button disabled={activePageNo === 1} onClick={() => handlePageNumber(activePageNo - 1)}>
                <span>Prev</span>
              </button>
              {Array.from({ length: totalPageNo }, (_, index) => (
                <button
                  className={`${activePageNo === index + 1 ? 'activePaginationbtn' : ''}`}
                  key={index}
                  onClick={() => handlePageNumber(index + 1)}
                >
                  {index + 1}
                </button>
              ))}
              <button disabled={activePageNo === totalPageNo} onClick={() => handlePageNumber(activePageNo + 1)}>
                <span>Next</span>
              </button>
            </div>
          </div> */}
        </div>
      </div>
    </>
  );
}