import React, { useEffect, useState } from 'react';
import filterTableIcon from '../../assets/filterTableIcon.png';
import '../Referral/Referral.css';
import { IoEyeOutline } from 'react-icons/io5';
import { FaCaretLeft, FaCaretRight } from 'react-icons/fa6';
import axios from 'axios';
import { URLS } from '../../Utils/app_urls';
import { useNavigate } from 'react-router-dom';
import Loader from '../../components/Loader/Loader';
import { getMontsFromDurationIndex } from '../../Utils/Utils';

export default function Staking() {
  const navigate = useNavigate();
  const [activePageNo, setActivePageNo] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [loader, setLoader] = useState(false);
  const [totalDataResult, setTotalDataResult] = useState(0);

  const [stakeData, setStakeData] = useState([]);
  const [RefFilterData, setRefFilterData] = useState([]);

  const handlePageNumber = (pageNo) => {
    if (pageNo >= 1 && pageNo <= totalPages) {
      setActivePageNo(pageNo);
      getAllStakes(pageNo);
    }
  };

  const getAllStakes = () => {
    setLoader(true);
    try {
      axios.post(`${URLS.getAllStakes}`,
        {
          "stakestatus": "stake"
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('acessToken')}`,
          },
        })
        .then((res) => {

          let array = [], data = res?.data?.data;
          for (var key in data) {
            let col = data[key];
            // console.log('testttttttttttttttt-',`${getMontsFromDurationIndex(col?.durationIndex)?.split('M')[0]} M${getMontsFromDurationIndex(col?.durationIndex)?.split('M')[1]}`);
            let obj = {
              createdAt: new Date(col?.createdAt).toLocaleDateString(),
              // stakePeriod: `${getMontsFromDurationIndex(col?.durationIndex)}` || col?.stakePeriod,
              stakePeriod: getMontsFromDurationIndex(col?.durationIndex) || col?.stakePeriod,
              apy: col?.apy || col?.stakeApy,
              stakestatus: col?.stakestatus === "stake" ? "Farmed" : "",
              stakeAmount: col?.stakeAmount,
              _id: col?._id
            }
            array.push(obj);
          }
          let totalResult = array.length
          let pageSize = 10
          setTotalDataResult(totalResult)
          // setTotalPages(Math.ceil(totalResult / pageSize));
          setTotalPages(10);
          setStakeData(array);
          setLoader(false);
        })
        .catch((err) => {
          setLoader(false);
        });
    } catch (error) {
      setLoader(false);
    }
  };

  const handleSorting = (time) => {
    let sorted;
    if (time !== "all") {
      sorted = stakeData.filter((data) => {
        return data?.stakePeriod === time
      })
      setRefFilterData(sorted);
    } else {
      setRefFilterData(stakeData)
    }
  }

  const handleRedirectToDetailsPage = (level) => {
    navigate(`/farming-details`, {
      state: level,
    });
  };

  useEffect(() => {
    getAllStakes(1);
  }, []);

  useEffect(() => {
    setRefFilterData(stakeData);
  }, [stakeData]);

  const handleFilterTable = (e) => {
    let filterText = e.target.value.toLowerCase();
    if (filterText === '') {

      setRefFilterData(stakeData);
    } else {
      const filteredData = stakeData.filter(item => {
        let { stakePeriod, apy, stakestatus, stakeAmount, createdAt } = item;
        return (
          stakePeriod.toString().toLowerCase().includes(filterText) ||
          apy.toString().toLowerCase().includes(filterText) ||
          stakestatus.toString().toLowerCase().includes(filterText) ||
          stakeAmount.toString().toLowerCase().includes(filterText) ||
          createdAt.toString().toLowerCase().includes(filterText)
        );
      });
      setRefFilterData(filteredData);
    }
  };

  if (loader) return <Loader />;

  return (
    <>
      <div className="referral_container">
        <div className="referralTable">
          <h1>Farm</h1>
          <div className=' d-flex justify-content-between mb-3 mb-md-0 flex-wrap gap-2 align-items-center'>
            <div className="filtertablebtn dropdown drop-down-box">
              <button type="button" id="dropdownMenu2" data-bs-toggle="dropdown" aria-expanded="true" className="filter_btn w-100 h-100">
                <img src={filterTableIcon} alt="filterTable Icon" />
                Filter
              </button>
              <ul className="dropdown-menu filter_drop_box" aria-labelledby="dropdownMenu2">
                <li><button onClick={() => handleSorting("all")} className="dropdown-item" type="button">All</button></li>
                <li><button onClick={() => handleSorting("3 Months")} className="dropdown-item" type="button">3 month</button></li>
                <li><button onClick={() => handleSorting("5 Months")} className="dropdown-item" type="button">5 month</button></li>
                <li><button onClick={() => handleSorting("8 Months")} className="dropdown-item" type="button">8 month</button></li>
                <li><button onClick={() => handleSorting("12 Months")} className="dropdown-item" type="button">12 month</button></li>
                <li><button onClick={() => handleSorting("24 Months")} className="dropdown-item" type="button">24 month</button></li>
              </ul>
            </div>
            <input type="text" placeholder='Search' onChange={handleFilterTable} />
          </div>
          <div className='w-100 responsiveTableParent'>
            <table className='w-100'>
              <thead className='affTableHeader'>
                <tr>
                  <th className='boder-Rght'>Farm Date</th>
                  <th className='boder-Rght'>Farm Period</th>
                  <th className='boder-Rght'>Farm APR</th>
                  <th className='boder-Rght'>Farm Status</th>
                  <th className='boder-Rght'>Farm Amount</th>
                  <th className='boder-Rght'>View</th>
                </tr>
              </thead>
              <tbody className='referralTableBody'>
                {
                  RefFilterData?.length > 0 ?
                    <>
                      {RefFilterData?.map((staking, index) => {
                        return (
                          <tr key={staking?._id}>
                            <td>{staking?.createdAt}</td>
                            <td>{`${staking?.stakePeriod}`}</td>
                            <td>{`${staking?.apy}`}</td>
                            <td>{staking?.stakestatus}</td>
                            <td>{staking?.stakeAmount}</td>
                            {/* <td>{conciseAddress(staking?.userAddress)}</td> */}
                            <td>
                              <span onClick={() => handleRedirectToDetailsPage(staking?._id)}>
                                <IoEyeOutline fontSize={20} />
                              </span>
                            </td>
                          </tr>
                        );
                      })}
                    </>
                    :
                    <tr>
                      <td colSpan="9" className='data_not fw-bold'>
                        No Data Available
                      </td>
                    </tr>
                }
              </tbody>
            </table>
          </div>
          <div className='w-100 d-flex justify-content-between'>
            <span className='fw-bold'>Showing {activePageNo} to {totalPages} of {totalDataResult} Entries</span>
            <div className='AffTxTableButton'>

              <button disabled={activePageNo === 1} onClick={() => handlePageNumber(activePageNo - 1)}>
                {/* <FaCaretLeft fontSize={18} /> */}
                <span>Prev</span>
              </button>
              {((totalPages > 0) ? [...Array(totalPages).keys()] : [...Array(1).keys()])
                .slice(Math.max(0, activePageNo - 3), Math.min(totalPages, activePageNo + 2))
                .map((_, index) => (
                  <button
                    className={`${activePageNo === index + Math.max(0, activePageNo - 3) + 1 && 'activePaginationbtn'}`}
                    key={index + Math.max(0, activePageNo - 3) + 1}
                    onClick={() => handlePageNumber(index + Math.max(0, activePageNo - 3) + 1)}
                  >
                    {index + Math.max(0, activePageNo - 3) + 1}
                  </button>
                ))}
              <button disabled={activePageNo === totalPages} onClick={() => handlePageNumber(activePageNo + 1)}>
                <span>Next</span>
              </button>

            </div>
          </div>
        </div>
      </div>
    </>
  );
}




