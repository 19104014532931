import React, { useEffect, useState } from 'react';
import filterTableIcon from '../../assets/filterTableIcon.png';
import '../Referral/Referral.css';
import { IoEyeOutline } from 'react-icons/io5';
import { FaCaretLeft, FaCaretRight } from 'react-icons/fa6';
import axios from 'axios';
import { URLS } from '../../Utils/app_urls';
import { useNavigate } from 'react-router-dom';
import Loader from '../../components/Loader/Loader';

export default function Vesting() {
  const navigate = useNavigate();
  const [activePageNo, setActivePageNo] = useState(1);
  const [totalPageNo, setTotalPageNo] = useState(1);
  const [loader, setLoader] = useState(false);

  const [leadershipBoardData, setLeadershipBoardData] = useState([]);
  const [RefFilterData, setRefFilterData] = useState([]);

  const handlePageNumber = (pageNo) => {
    if (pageNo >= 1 && pageNo <= totalPageNo) {
      setActivePageNo(pageNo);
      getLeadershipBoardData(pageNo);
    }
  };


  const getLeadershipBoardData = (pageNo) => {
    setLoader(true);
    try {
      axios.get(`${URLS.getAllLeadership}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('acessToken')}`,
        },
      })
        .then((res) => {
          setLeadershipBoardData(res?.data?.data || []);
          setLoader(false);
        })
        .catch((err) => {
          setLoader(false);
        });
    } catch (error) {
      setLoader(false);
    }
  };

  const handleSorting = async (order) => {
    setLoader(true);
    try {
      let res = await axios.get(`${URLS.dummy}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('acessToken')}`,
        },
      });
      setRefFilterData(res?.data?.data?.result || []);
      setLoader(false);
    } catch (error) {
      setLoader(false);
    }
  };

  const handleRedirectToDetailsPage = (level) => {
    navigate(`/vesting-details`, {
      state: level,
    });
  };

  useEffect(() => {
    getLeadershipBoardData(1);
  }, []);

  useEffect(() => {
    setRefFilterData(leadershipBoardData);
  }, [leadershipBoardData]);

  let tableBtnArr = Math.max(1, Math.ceil(RefFilterData?.length || 0));
  const handleFilterTable = (e) => {
    let filterText = e.target.value.toLowerCase();
    if (filterText === '') {

      setRefFilterData(leadershipBoardData);
    } else {
      const filteredData = leadershipBoardData.filter(item => {
        let { qualifyAmountInUSD, poolSharePercentage, level, level1ContributionPercentage } = item;
        return (
          qualifyAmountInUSD.toString().toLowerCase().includes(filterText) ||
          poolSharePercentage.toString().toLowerCase().includes(filterText) ||
          level.toString().toLowerCase().includes(filterText) ||
          level1ContributionPercentage.toString().toLowerCase().includes(filterText)
        );
      });
      setRefFilterData(filteredData);
    }
  };

  if(loader) return <Loader />;
  
  return (
    <>
      <div className="referral_container">
        <div className="referralTable">
          <h1>Vesting</h1>
          <div className=' d-flex justify-content-end flex-wrap gap-2 align-items-center'>
            <input type="text" placeholder='Search' onChange={handleFilterTable} />
          </div>
          <div className='w-100 responsiveTableParent'>
            <table className='w-100'>
              <thead className='affTableHeader'>
                <tr>
                  <th className='boder-Rght'>Data 1</th>
                  <th className='boder-Rght'>Data 2</th>
                  <th className='boder-Rght'>Data 3</th>
                  <th className='boder-Rght'>Data 4</th>
                  <th className='boder-Rght'>Data 5</th>
                </tr>
              </thead>
              <tbody className='referralTableBody'>
                {
                  RefFilterData?.length > 0 ?
                    <>
                      {RefFilterData?.map((referral, index) => (
                        <tr key={referral?._id}>
                          <td>{referral?.qualifyAmountInUSD}</td>
                          <td>{referral?.poolSharePercentage}</td>
                          <td>{referral?.level}</td>
                          <td>{referral?.level1ContributionPercentage}</td>
                          <td><span onClick={() => handleRedirectToDetailsPage(referral?.level)} >
                            <IoEyeOutline fontSize={20} />
                          </span></td>
                        </tr>
                      ))}
                    </>
                    :
                    <tr>
                      <td colSpan="5" className='data_not fw-bold'>
                        No Data Available
                      </td>
                    </tr>
                }
              </tbody>
            </table>
          </div>
          <div className='w-100 d-flex justify-content-between'>
            <span className='fw-bold'>Showing {activePageNo} to {totalPageNo} of {totalPageNo} Entries</span>
            <div className='AffTxTableButton'>

              <button disabled={activePageNo === 1} onClick={() => handlePageNumber(activePageNo - 1)}>
                <span>Prev</span>
              </button>
              {[...Array(totalPageNo).keys()].map((index) => (
                <button
                  className={`${activePageNo === index + 1 && 'activePaginationbtn'}`}
                  key={index}
                  onClick={() => handlePageNumber(index + 1)}
                >
                  {index + 1}
                </button>
              ))}
              <button disabled={activePageNo === totalPageNo} onClick={() => handlePageNumber(activePageNo + 1)}>
                <span>Next</span>
              </button>

            </div>
          </div>
        </div>
      </div>
    </>
  );
}




