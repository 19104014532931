
import React, { useContext, useEffect, useState } from 'react';
import { FaCaretLeft, FaCaretRight } from "react-icons/fa6";
import filterTableIcon from '../../assets/filterTableIcon.png';
import { Table } from 'react-bootstrap';
import axios from 'axios';
import { URLS } from '../../Utils/app_urls';
import { IoEyeOutline } from "react-icons/io5";
import '../Migration/TxTable.css';
import { useLocation } from 'react-router-dom';
import conciseAddress from '../../Utils/ConciseAddress';

export default function TxnTable() {
    const [loader, setLoader]=useState(false);
    const [stakeReward, setStakeReward] = useState()
    const location = useLocation();

    const handleGetRewards =()=>{
        const id = location.state;
        axios.post(URLS.getStakeRewards,{
            filter: {
                stakeId: id,
            }
        },{
            headers: {
                Authorization: `Bearer ${localStorage.getItem('acessToken')}`
            }
        })
        .then((res)=>{
            setStakeReward(res?.data?.data?.result);
        })
        .catch((err)=>{
        })
    }
    useEffect(()=>{
        handleGetRewards()
    },[])
    return (
        <>
            <div className={`tnxTable_main d-flex flex-column gap-3 ${loader && 'minimizeOpacity'}`}>
                <h1 className='m-0'>Transaction History</h1>
                <div className="tableFilterSection d-flex justify-content-between align-items-end flex-wrap gap-2">
                    {/* <div className="sorttablebtn dropdown drop-down-box">
                        <button type="button" id="dropdownMenu2" data-bs-toggle="dropdown" aria-expanded="true" className="filter_btn w-100 h-100">
                            <img src={filterTableIcon} alt="filterTable Icon" />
                            Filter
                        </button>
                        <ul className="dropdown-menu filter_drop_box" aria-labelledby="dropdownMenu2">
                            <li><button onClick={() => handleSorting('asc')} className="dropdown-item" type="button">Asc order</button></li>
                            <li><button onClick={() => handleSorting('dsc')} className="dropdown-item" type="button">Dsc order</button></li>
                        </ul>
                    </div>
                    <input type="text" placeholder='Search' onChange={handleFilterTable} /> */}
                </div>
                <div className="tableContainer">
                    <div className='widthRespos'>
                        <Table className='w-100' hover>
                            <thead className='tnxHistoryheader'>
                                <tr>
                                    <th className='boder-Rght'>TX Hash</th>
                                    <th className='boder-Rght'>Withdrawal Amount</th>
                                    <th className='boder-Rght'>User Wallet</th>
                                    <th className='boder-Rght'>Stake ID</th>
                                    <th className='boder-Rght'>Created Date</th>
                                </tr>
                            </thead>
                            <tbody className='tnxhtrbdytbl'>
                                {stakeReward?.length > 0 ? (
                                    stakeReward?.map((item, index) => (
                                        <tr key={index}>
                                            <td>{conciseAddress(item.transactionHash)}</td>
                                            <td>{item.withdrawAmount}</td>
                                            <td>{conciseAddress(item.userWallet)}</td>
                                            <td>{conciseAddress(item.stakeId)}</td>
                                            <td style={{ width: '130px' }}>{item.createdAt.split('T')[0]}</td>
                                        </tr>
                                    ))
                                ) : (
                                    <tr>
                                        <td colSpan="9" className='data_not fw-bold'>No Data Available</td>
                                    </tr>
                                )}
                            </tbody>
                        </Table>
                    </div>
                    {/* <div className="TxtableFooter">
                        <span className='fw-bold'>Showing {activePageNo} to {totalPageNo} of {totalPageNo} Entries</span>
                        <div className='TxTableButton'>
                            <button disabled={activePageNo === 1} onClick={handleDecPageNo}>Prev</button>
                            {[...Array(5)].map((_, i) => (
                                <button key={i} className={activePageNo === activePageNo + i && 'activePaginationbtn'} disabled={activePageNo + i > totalPageNo} onClick={() => handlePageNumber(activePageNo + i)}> {activePageNo + i} </button>
                            ))}
                            <button disabled={activePageNo >= totalPageNo - 4} onClick={handleIncPageNo}>Next</button>
                        </div>
                    </div> */}
                </div>
            </div>
        </>
    );
}
